import React, { ReactElement } from 'react';
import loadable from '@loadable/component';

const NafCenterTypeSwitch = loadable(() => import('./NafCenterTypeSwitch'), {
  resolveComponent: (components) => components.NafCenterTypeSwitch,
});
const NotFound = loadable(() => import('../pages/errors/NotFound'), {
  resolveComponent: (components) => components.NotFound,
});

export const NafCentersDocumentRoute = ({
  match: {
    params: { slug },
  },
}: {
  match: { params: { slug: string } };
}): ReactElement => {
  if (!slug) {
    return <NotFound />;
  }
  return <NafCenterTypeSwitch slug={slug} />;
};
